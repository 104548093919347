
























































































































































































import SelectBranch from "@/components/custom/select/SelectBranch.vue";
import SelectCustomer from "@/components/custom/select/SelectCustomer.vue";
import SelectMasterType from "@/components/custom/select/SelectMasterType.vue";
import SelectIc from "@/components/InvoiceControllingReport/SelectIc.vue";
import currencyFilter from "@/filters/currency.filter";
import dateFormat from "@/filters/date.filter";
import { generateUUID } from "@/helpers/uuid";
import useBlob from "@/hooks/useBlob";
import useDate from "@/hooks/useDate";
import useInvoiceControllingReport from "@/hooks/useInvoiceControllingReport";
import MNotification from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  ONE,
  PAGE_SIZE_OPTIONS,
} from "@/models/constant/global.constant";
import { Pagination } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import {
  BooleanString,
  FilterField,
  ReportInvControllingParams,
  ReportInvControllingResponseDto,
} from "@/models/interface/invoice-controlling-report";
import { SorterProps } from "@/types";
import { FormUtils } from "@/utils/FormUtils";
import { FormModel } from "ant-design-vue";
import { Component, Mixins, Ref } from "vue-property-decorator";

type Record = ReportInvControllingResponseDto & {
  key: string;
};

@Component({
  components: {
    SelectMasterType,
    SelectBranch,
    SelectCustomer,
    SelectIc,
  },
})
export default class InvoiceControllingReport extends Mixins(MNotification) {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  @Ref("formModel")
  formModel!: FormModel;

  dataReport: Pagination<Record> = {
    currentPage: 0,
    data: [],
    totalElements: 0,
    totalPages: 0,
  };

  pagination = {
    page: ONE,
    limit: DEFAULT_PAGE_SIZE,
    sorts: undefined as string | undefined,
    direction: undefined as "asc" | "desc" | undefined,
  };

  form: FilterField = {
    branch: undefined,
    isInvoiced: undefined,
    date: [],
    internalContract: undefined,
    customer: undefined,
    status: [],
    unitCode: undefined,
    isBackup: undefined,
  };

  readonly statusOptions: Option[] = [
    {
      key: "Approved",
      label: "Approved",
      value: "Approved",
    },
    {
      key: "Partial Ended",
      label: "Partial Ended",
      value: "Partial Ended",
    },
    {
      key: "Ended",
      label: "Ended",
      value: "Ended",
    },
    {
      key: "Closed",
      label: "Closed",
      value: "Closed",
    },
  ];

  columns = [
    {
      title: this.$t("lbl_customer_name"),
      dataIndex: "customerName",
      key: "customerName",
      ellipsis: true,
      scopedSlots: { customRender: "ellipsis" },
      sorter: true,
    },
    {
      title: this.$t("lbl_customer_location"),
      dataIndex: "customerLocation",
      key: "customerLocation",
      ellipsis: true,
      scopedSlots: { customRender: "ellipsis" },
      sorter: true,
    },
    {
      title: this.$t("lbl_unit_code"),
      sorter: true,
      key: "unitCode",
      scopedSlots: { customRender: "unitCode" },
    },
    {
      title: this.$t("lbl_internal_contract_no"),
      sorter: true,
      dataIndex: "refNumber",
      key: "refNumber",
      customRender: (text: string): string => text || "-",
    },
    {
      title: this.$t("lbl_status_ic"),
      sorter: true,
      dataIndex: "icStatusDesc",
      key: "icStatusDesc",
    },
    {
      title: this.$t("lbl_contract_period"),
      sorter: true,
      dataIndex: "contractPeriod",
      key: "contractPeriod",
      width: "250px",
      customRender: (text: string): string => text || "-",
    },
    {
      title: this.$t("lbl_invoice_number"),
      sorter: true,
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      customRender: (text: string): string => text || "-",
    },
    {
      title: this.$t("lbl_invoice_date"),
      sorter: true,
      key: "invoiceDate",
      dataIndex: "invoiceDate",
      customRender: (text: string): string => dateFormat(text),
    },
    {
      title: this.$t("lbl_amount"),
      sorter: true,
      key: "amount",
      dataIndex: "amount",
      customRender: (text: number): string => currencyFilter(text),
    },
    {
      title: this.$t("lbl_billing_period"),
      sorter: true,
      dataIndex: "billingPeriod",
      key: "billingPeriod",
      customRender: (text: string): string => text || "-",
    },
  ];

  booleanOptions: Array<Option> = [
    {
      label: this.$t("lbl_yes").toString(),
      key: 0,
      value: "YES" as BooleanString,
    },
    {
      label: this.$t("lbl_no").toString(),
      key: 1,
      value: "NO" as BooleanString,
    },
  ];

  loading = {
    find: false,
    download: false,
  };

  get validationSchema() {
    return {
      date: [FormUtils.mandatory(), FormUtils.maxOneYear()],
    };
  }

  fetchReport(params?: ReportInvControllingParams): void {
    const { findAll } = useInvoiceControllingReport();

    this.loading.find = true;
    findAll(params)
      .then(res => {
        this.dataReport = {
          ...res,
          data: res.data.map(item => ({
            ...item,
            key: generateUUID(),
          })),
        };
      })
      .finally(() => {
        this.loading.find = false;
      });
  }

  onSubmit(): void {
    this.formModel.validate(valid => {
      if (!valid) return;

      this.pagination.page = DEFAULT_PAGE;
      const params: ReportInvControllingParams = this.buildParamsList({
        state: this.form,
        page: this.pagination.page,
        limit: this.pagination.limit,
        sorts: this.pagination.sorts,
        direction: this.pagination.direction,
      });
      this.fetchReport(params);
    });
  }

  handleReset(): void {
    this.formModel.resetFields();
    this.form.status = [];
  }

  onChangeTable(
    pagination: {
      total: number;
      current: number;
      pageSize: number;
    },
    _,
    { columnKey, order }: SorterProps
  ): void {
    this.pagination.sorts = undefined;
    this.pagination.direction = undefined;
    this.pagination.page = pagination.current;

    if (this.pagination.limit !== pagination.pageSize) {
      this.pagination.page = DEFAULT_PAGE;
    }

    this.pagination.limit = pagination.pageSize;

    if (order === "ascend") {
      this.pagination.sorts = columnKey;
      this.pagination.direction = "asc";
    } else if (order === "descend") {
      this.pagination.sorts = columnKey;
      this.pagination.direction = "desc";
    }

    const params = this.buildParamsList({
      state: this.form,
      page: this.pagination.page,
      limit: this.pagination.limit,
      sorts: this.pagination.sorts,
      direction: this.pagination.direction,
    });
    this.fetchReport(params);
  }

  buildParamsList({
    state,
    page,
    limit,
    sorts,
    direction,
  }: {
    state: FilterField;
    page?: number;
    limit?: number;
    sorts?: string;
    direction?: "asc" | "desc";
  }): ReportInvControllingParams {
    const { toStartDay, toEndDay } = useDate();

    let startDate: string | undefined;
    let endDate: string | undefined;

    if (state.date && state.date.length === 2) {
      const [start, end] = state.date;
      startDate = toStartDay(start).format();
      endDate = toEndDay(end).format();
    }

    let isInvoicing: boolean | undefined;
    if (state.isInvoiced) {
      isInvoicing = state.isInvoiced === "YES";
    }

    let isBackup: boolean | undefined;
    if (state.isBackup) {
      isBackup = state.isBackup === "YES";
    }

    let icStatus: string | undefined;
    if (state.status && state.status.length > 0) {
      icStatus = state.status.join(",");
    }

    return {
      startDate,
      endDate,
      limit,
      page: page ? page - ONE : undefined,
      icStatus,
      branchId: state.branch?.key,
      customerId: state.customer?.key,
      unitCode: state.unitCode,
      icId: state.internalContract?.key,
      unitBackup: isBackup,
      isInvoicing: isInvoicing,
      sort: sorts,
      direction: direction,
    };
  }

  buildParamsDownload(state: FilterField): ReportInvControllingParams {
    const { buildReportHeader } = useInvoiceControllingReport();
    const params: ReportInvControllingParams = this.buildParamsList({
      state,
    });
    params.params = buildReportHeader(state);
    return params;
  }

  handleDownload(): void {
    const { download } = useInvoiceControllingReport();
    const { toDownload } = useBlob();
    const params: ReportInvControllingParams = this.buildParamsDownload(
      this.form
    );
    this.loading.download = true;
    download(params)
      .then(res => {
        toDownload(res, "invoice_controlling_report.xlsx");
      })
      .finally(() => {
        this.loading.download = false;
      });
  }
}
