import { AmortizationStatusEnum } from "@/models/enums/amortization.enum";
import { AmortizationDetailJournalResponseDto } from "./AmortizationDetailJournalResponseDto.interface";
import { AmortizationDetailUnitResponseDto } from "./AmortizationDetailUnitResponseDto.interface";

export class AmortizationDetailResponseDto {
  amortizationId = "";
  amortizationNumber = "";
  bankId = "";
  bankName = "";
  branchId = "";
  branchName = "";
  cashOutNumber = "";
  cashOutSecureId = "";
  currencyId = "";
  currencyName = "";
  currencyRate = 1;
  description = "";
  documentNumber = "";
  endPeriod = "";
  expenseAccountCode = "";
  expenseAccountDescription = "";
  expenseAccountId = "";
  journals: AmortizationDetailJournalResponseDto[] = [];
  leasingContractNumber = "";
  leasingId = "";
  leasingNumber = "";
  lessorId = "";
  lessorName = "";
  periodLength = 0;
  prepaidAccountCode = "";
  prepaidAccountDescription = "";
  prepaidAccountId = "";
  startPeriod = "";
  status!: AmortizationStatusEnum;
  totalAmortizationAmount = 0;
  transactionDate = "";
  transactionType = "";
  units: AmortizationDetailUnitResponseDto[] = [];
}
