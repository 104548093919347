var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_invoice_overdue") } },
    [
      _c(
        "a-form-model",
        {
          attrs: {
            "label-align": "left",
            model: _vm.filterForm,
            "label-col": { span: 4 },
            "wrapper-col": { span: 6 }
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.validateForm.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_ic_created_by") } },
            [
              _c("SelectUser", {
                attrs: {
                  "allow-clear": "",
                  "data-testid": "invoice-overdue-filter-ic-created-by",
                  "label-in-value": ""
                },
                model: {
                  value: _vm.filterForm.icCreatedBy,
                  callback: function($$v) {
                    _vm.$set(_vm.filterForm, "icCreatedBy", $$v)
                  },
                  expression: "filterForm.icCreatedBy"
                }
              })
            ],
            1
          ),
          _vm.showDatepicker
            ? _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t("lbl_date") } },
                [
                  _c("a-range-picker", {
                    attrs: {
                      format: _vm.DEFAULT_DATE_FORMAT,
                      placeholder: [
                        _vm.$t("lbl_start_date"),
                        _vm.$t("lbl_end_date")
                      ],
                      "data-testid": "invoice-overdue-filter-date"
                    },
                    model: {
                      value: _vm.filterForm.date,
                      callback: function($$v) {
                        _vm.$set(_vm.filterForm, "date", $$v)
                      },
                      expression: "filterForm.date"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_customer_name") } },
            [
              _c("SelectCustomer", {
                attrs: { "data-testid": "invoice-overdue-filter-customer" },
                on: { "update:meta": _vm.onChangeCustomer },
                model: {
                  value: _vm.filterForm.customerName,
                  callback: function($$v) {
                    _vm.$set(_vm.filterForm, "customerName", $$v)
                  },
                  expression: "filterForm.customerName"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_unit_backup") } },
            [
              _c("SelectUnitBackup", {
                attrs: { "data-testid": "invoice-overdue-filter-unit-backup" },
                model: {
                  value: _vm.filterForm.unitBackup,
                  callback: function($$v) {
                    _vm.$set(_vm.filterForm, "unitBackup", $$v)
                  },
                  expression: "filterForm.unitBackup"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { "wrapper-col": { span: 12, offset: 4 } } },
            [
              _c(
                "a-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.loading.find,
                    "html-type": "submit",
                    "data-testid": "invoice-overdue-btn-find"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("a-table", {
        attrs: {
          size: "small",
          columns: _vm.columnsTable,
          "data-source": _vm.dataReport.data,
          "row-key": function(r, i) {
            return i
          },
          scroll: { y: 600, x: "calc(100% + 500px)" },
          loading: _vm.loading.find,
          pagination: {
            showTotal: function() {
              return _vm.$t("lbl_total_items", {
                total: _vm.dataReport.totalElements
              })
            },
            total: _vm.dataReport.totalElements,
            current: _vm.pagination.page,
            showSizeChanger: true,
            pageSizeOptions: _vm.PAGE_SIZE_OPTIONS
          },
          "data-testid": "invoice-overdue-table"
        },
        on: { change: _vm.onChangeTable },
        scopedSlots: _vm._u([
          {
            key: "unitCode",
            fn: function(text, record) {
              return _c(
                "span",
                {},
                [
                  record.isUnitBackup
                    ? [
                        _c(
                          "a-tooltip",
                          { attrs: { title: _vm.$t("lbl_unit_backup") } },
                          [
                            _c("a-icon", {
                              attrs: {
                                type: "interaction",
                                theme: "twoTone",
                                "two-tone-color": "#52c41a"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    : _vm._e(),
                  _c("span", [
                    _vm._v(" " + _vm._s(record.unitCode || "-") + " ")
                  ])
                ],
                2
              )
            }
          },
          {
            key: "contractPeriod",
            fn: function(_, row) {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm._f("date")(row.startDate)) +
                    " " +
                    _vm._s("-") +
                    " " +
                    _vm._s(_vm._f("date")(row.endDate)) +
                    " "
                )
              ]
            }
          }
        ])
      }),
      _c(
        "div",
        { staticClass: "text-right mt-2" },
        [
          _vm.$can("read", "invoice-overdue")
            ? _c(
                "a-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.loading.download,
                    "data-testid": "invoice-overdue-btn-download"
                  },
                  on: { click: _vm.download }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }